import {Card, CardBody, Label} from "reactstrap";
import {Controller} from 'react-hook-form'
import CustomSelect from "../../components/CustomSelect";
import ChatGptStyles from "../../data/chatGptStyles.json";
import ChatGptTones from "../../data/chatGptTones.json";

const Styles = ({control}) => {
    return (
        <Card>
            <h5 className="card-header bg-transparent border-bottom text-uppercase">
                Content parameters
            </h5>
            <CardBody>
                <div className="mb-3">
                    <Label>Writing style</Label>
                    <Controller render={({field: {value, onChange}}) => (
                        <CustomSelect name="writing_style" options={Object.values(ChatGptStyles)} value={value}
                                      onChange={onChange}/>
                    )} name="writing_style" control={control}/>
                </div>
                <div>
                    <Label>Writing tone</Label>
                    <Controller render={({field: {value, onChange}}) => (
                        <CustomSelect name="writing_tone" options={Object.values(ChatGptTones)} value={value}
                                      onChange={onChange}/>
                    )} name="writing_tone" control={control}/>
                </div>
            </CardBody>
        </Card>
    )
}

export default Styles
