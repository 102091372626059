import {Button, Input, InputGroup, Pagination, PaginationItem, PaginationLink} from "reactstrap";
import paginationCounts from '../../data/paginationCounts.json'
import {usePagination} from "./usePagination";
import {useState} from "react";

const CustomPagination = ({total, limit = 10, setLimit, page, setPage}) => {
    const paginationCount = Math.ceil(total / limit)
    const [localLimit, setLocalLimit] = useState(limit)
    const paginationRange = usePagination({
        totalCount: total,
        pageSize: limit,
        currentPage: page,
        siblingCount: 1
    })

    return total > 0 &&
        <div className="d-flex gap-2 justify-content-between align-items-start">
            {setLimit && (
                <div className="d-flex gap-2">
                    <select
                        className="form-control w-auto"
                        value={limit}
                        onChange={e => setLimit(e.target.value)}
                    >
                        {paginationCounts.map(item => (
                            <option key={item.value} value={item.value}>{item.label}</option>
                        ))}
                    </select>

                    <InputGroup className="w-auto">
                        <Input type="number" value={localLimit} onChange={e => {
                            const value = e.target.value
                            if (value !== 'e') {
                                setLocalLimit(value)
                            }
                        }}/>
                        <Button color="primary" onClick={() => setLimit(localLimit)}>
                            Apply
                        </Button>
                    </InputGroup>
                </div>
            )}
            <Pagination>
                <PaginationItem disabled={page === 1} onClick={() => {
                    page !== 1 && setPage(prev => prev - 1)
                }
                }>
                    <PaginationLink>
                        <i className="bx bx-left-arrow-alt"/>
                    </PaginationLink>
                </PaginationItem>
                {paginationRange.map((item) => (
                    item === '...' ? (
                        <PaginationItem key={item}>
                            <PaginationLink>...</PaginationLink>
                        </PaginationItem>
                    ) : (
                        <PaginationItem onClick={() => setPage(item)} key={item} active={page === item}>
                            <PaginationLink>{item}</PaginationLink>
                        </PaginationItem>
                    )
                ))}
                <PaginationItem disabled={paginationCount === page} onClick={() => {
                    paginationCount !== page && setPage(prev => prev + 1)
                }
                }>
                    <PaginationLink>
                        <i className="bx bx-right-arrow-alt"/>
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        </div>
}

export default CustomPagination
