import axios from './index'

const path = 'dashboard/articles'

class Articles {
    get = (params) => axios.get(`${path}/index`, {params})
    getById = id => axios.get(`${path}/show/${id}`)
    add = data => axios.post(`${path}/store`, data)
    update = (data) => axios.post(`${path}/update/${data.id}`, data)
    updateStatus = (id, status) => axios.post(`${path}/updateStatus/${id}/${status}`)
    translate = (data) => axios.post(`${path}/translate`, data)
    bulkCreate = (data) => axios.post(`${path}/store/bulk`, data)
    getProgress = () => axios.get(`${path}/store/progressBar`)
    delete = data => axios.post(`${path}/delete`, data)
}

export default new Articles()
