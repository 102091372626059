import {Card, CardBody, Label} from "reactstrap";
import {Controller} from "react-hook-form";
import CustomSelect from "../../components/CustomSelect";

const Language = ({control, supportedLanguages}) => {
    return (
        <Card>
            <CardBody>
                <div className="mb-3">
                    <Label form="selectedLang">Language</Label>
                    <Controller render={({field: {value, onChange}}) => (
                        <CustomSelect name="selectedLang" value={value} onChange={onChange}
                                      options={supportedLanguages}/>
                    )} name="selectedLang" control={control}/>
                </div>
            </CardBody>
        </Card>
    )
}

export default Language
