import {useForm} from "react-hook-form";
import {Button, Col, Label, ModalBody, ModalFooter, Row, Spinner} from "reactstrap";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import ThirdParty from "../../api/third-party";
import Articles from "../../api/articles";

const Translate = ({form, setForm, fetchData}) => {
    const data = form?.data
    const [languages, setLanguages] = useState([])
    const [isFetching, setIsFetching] = useState(true)
    const {handleSubmit, control, setValue, setError, formState: {errors}} = useForm()
    const [selectedLangs, setSelectedLangs] = useState([])
    const [loader, setLoader] = useState(false)
    const submit = async () => {
        setLoader(true)
        try {
            await Articles.translate({
                ids: data?.map(item => item?.id),
                languages: selectedLangs
            })
            fetchData()
            setForm({})
        } catch (e) {
            toast.error('Error')
        } finally {
            setLoader(false)
        }
    }

    const fetchLanguages = async () => {
        setIsFetching(true)
        const {data} = await ThirdParty.getLanguages('en')
        setLanguages(data?.languages?.map(item => {
            return {
                value: item?.language,
                label: item?.name
            }
        }))
        setIsFetching(false)
    }

    const onChange = value => {
        if (selectedLangs.includes(value)) {
            setSelectedLangs(prev => ([
                ...prev.filter(item => item !== value)
            ]))
            return
        }
        setSelectedLangs(prev => ([
            ...prev,
            value
        ]))
    }

    const getCheckBoxs = (item) => {
        return <Label className="d-flex gap-1" key={item.value}>
            <div className="form-check">
                <input type="checkbox"
                       onChange={() => onChange(item.value)}
                       checked={selectedLangs.includes(item.value)}
                       className="form-check-input"/>
            </div>
            {item.label}
        </Label>
    }

    const checkAll = () => {
        if (selectedLangs.length === languages.length) {
            setSelectedLangs(['en'])
            return
        }
        setSelectedLangs(languages?.map(item => item.value))
    }

    useEffect(() => {
        fetchLanguages()
    }, [])

    useEffect(() => {
        if (data?.length) {
            let langData = []
            data.forEach(item => {
                item.used_languages.forEach(lang => {
                    langData.push(lang)
                })
            })
            langData = [...new Set(langData)]
            setSelectedLangs(langData)
        }
    }, [])

    return (
        <form onSubmit={handleSubmit(submit)}>
            <ModalBody>
                {isFetching ? <div className="d-flex justify-content-center">
                    <Spinner size="lg" color="primary"/>
                </div> : (
                    <Row>
                        <Col sm={12}>
                            <Label className="d-flex gap-1">
                                <div className="form-check">
                                    <input type="checkbox"
                                           onChange={checkAll}
                                           checked={selectedLangs.length === languages.length}
                                           className="form-check-input"/>
                                </div>
                                All
                            </Label>
                        </Col>
                        <Col sm={12} md={3}>
                            {languages.map((item, index) => (
                                index < 34 && getCheckBoxs(item)
                            ))}
                        </Col>
                        <Col sm={12} md={3}>
                            {languages.map((item, index) => (
                                index >= 34 && index < 68 && getCheckBoxs(item)
                            ))}
                        </Col>
                        <Col sm={12} md={3}>
                            {languages.map((item, index) => (
                                index >= 68 && index < 102 && getCheckBoxs(item)
                            ))}
                        </Col>
                        <Col sm={12} md={3}>
                            {languages.map((item, index) => (
                                index >= 102 && getCheckBoxs(item)
                            ))}
                        </Col>
                    </Row>
                )}
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end gap-1">
                    <Button outline type="button" onClick={() => setForm(false)} color="secondary">Close</Button>
                    <Button disabled={loader || isFetching} type="submit" color="primary">
                        {loader ? <Spinner color="light" size="sm"/> : 'Translate'}
                    </Button>
                </div>
            </ModalFooter>
        </form>
    )
}

export default Translate
