import {Input, Label} from "reactstrap";
import {Controller} from "react-hook-form";
import FormHelper from "../../helpers/form";

const Title = ({control, watch, errors}) => {
    const selectedLang = watch('selectedLang')?.value
    const name = `${selectedLang}.title`
    return (
        <div className="mb-3">
            <Label form={name}>Title</Label>
            <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                <Input
                    id={name}
                    className={`form-control ${errors?.[selectedLang]?.title && 'is-invalid'}`}
                    value={value}
                    onChange={onChange}
                />
            )} name={name} control={control}/>
            {FormHelper.generateFormFeedback(errors, name)}
        </div>
    )
}

export default Title
