import {useEffect, useState} from "react";
import Category from "../../api/category";
import {Controller} from "react-hook-form";
import {Label} from "reactstrap";
import CustomSelect from "../../components/CustomSelect";

const ChatGPTCategories = ({control, errors, refreshData, setValue}) => {
    const [categories, setCategories] = useState([])

    const fetchCategories = async () => {
        const {data} = await Category.getSelect({type: 2})
        setCategories(data)
    }

    useEffect(() => {
        if (refreshData?.categories?.length) {
            setCategories(prev => ([
                ...prev,
                ...refreshData?.categories
            ]))
            setValue('gpt_categories', refreshData?.categories)
        }
    }, [refreshData])

    useEffect(() => {
        fetchCategories()
    }, [])

    return (
        <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
            <div className="mb-3">
                <Label>ChatGPT categories</Label>
                <CustomSelect isClearable onChange={onChange} isMulti value={value} errors={errors} options={categories}
                              name="gpt_categories"/>
            </div>
        )} name="gpt_categories" control={control}/>
    )
}

export default ChatGPTCategories
