import {useEffect, useState} from "react";
import Category from "../../api/category";
import {Controller} from "react-hook-form";
import {Button, Label, Modal, ModalHeader} from "reactstrap";
import CustomSelect from "../../components/CustomSelect";
import Add from "../Categories/Add";

const Categories = ({control, errors, setValue}) => {
    const [categories, setCategories] = useState([])
    const [form, setForm] = useState(null)

    const fetchCategories = async () => {
        const {data} = await Category.getSelect({type: 1})
        const oldData = categories?.map(x => x?.value)
        if (oldData?.length) {
            setValue('main_categories', data?.filter(item => !oldData.includes(item.value)))
        }
        setCategories(data)
    }

    useEffect(() => {
        fetchCategories()
    }, [])

    return (
        <>
            <Modal className="modal-dialog-centered" isOpen={form?.status} toggle={() => setForm({})}>
                <ModalHeader
                    toggle={() => setForm({})}>{form?.data ? 'Edit' : 'Add'}</ModalHeader>
                <Add forArticle fetchData={fetchCategories} form={form} setForm={setForm}/>
            </Modal>
            <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                <div className="mb-3 w-100">
                    <Label>Categories</Label>
                    <div className="d-flex gap-2 w-100">
                        <CustomSelect isClearable onChange={onChange} isMulti value={value} errors={errors}
                                      options={categories}
                                      className="w-100"
                                      name="main_categories"/>
                        <Button color="primary" type="button" onClick={() => setForm({status: true})}>
                            <i className="bx bx-plus"/>
                        </Button>
                    </div>
                </div>
            )} name="main_categories" control={control}/>
        </>
    )
}

export default Categories
