import Breadcrumbs from "components/Common/Breadcrumb";
import {Badge, Button, Card, CardBody, Col, Modal, ModalHeader, Row, Spinner, UncontrolledTooltip} from "reactstrap";
import {useEffect, useState} from "react";
import moment from "moment";
import UserApi from "../../api/user";
import User from "../../helpers/user";
import AddModal from "./AddModal";
import Form from "../../helpers/form";
import CustomPagination from "../../components/CustomPagination";
import Can from "../../components/Common/Can";

const Users = () => {
    const [data, setData] = useState([])
    const [isFetching, setIsFetching] = useState(true)
    const [loader, setLoader] = useState({})
    const [addModal, setAddModal] = useState(false)
    const [total, setTotal] = useState(0)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)

    document.title = 'Users | Newtimes'


    const fetchUsers = async () => {
        const data = await UserApi.get()
        setTotal(data?.meta?.total)
        setData(data?.data?.users?.map((item, index) => {
            return {
                ...item,
                index: <div className="d-flex align-items-center gap-1">
                    <Badge color="primary">{index + 1}</Badge>
                </div>,
                name: <div className="d-flex align-items-center gap-1">
                    {item?.photo ? (
                        <img
                            style={{objectFit: 'cover'}}
                            src={Form.generateFileUrl(item?.photo)}
                            alt={item.name}
                            className="avatar-md rounded-circle img-thumbnail"
                        />
                    ) : (
                        <div className="avatar-md rounded-circle img-thumbnail">
                            {User.getFirstChars(item)}
                        </div>
                    )}
                    <span>{item.name}</span>
                </div>,
                role: item?.role_name,
                status: <Badge
                    color={item?.status ? 'success' : 'secondary'}>{item?.status ? 'Active' : 'Deactive'}</Badge>,
                createdAt: moment(item?.created_at).format('DD.MM.YYYY HH:mm'),
                updatedAt: moment(item?.updated_at).format('DD.MM.YYYY HH:mm'),
                actions: <div className="d-flex gap-1">
                    <Can action="user_edit">
                        <Button
                            size="sm"
                            type="button"
                            color="success"
                            disabled={loader?.[item.id]}
                            id={`user-${item.id}-update-role`}
                            onClick={() => setAddModal({status: true, data: item})}
                        >
                            <i className="bx bx-pencil"/>
                        </Button>
                        <UncontrolledTooltip target={`user-${item.id}-update-role`} placement="bottom">
                            Edit
                        </UncontrolledTooltip>
                    </Can>
                </div>
            }
        }))
    }

    const fetchData = async () => {
        setIsFetching(true)
        await fetchUsers()
        setIsFetching(false)
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className="page-content">
            <Modal className="modal-dialog-centered" isOpen={addModal?.status} toggle={() => setAddModal(false)}>
                <ModalHeader
                    toggle={() => setAddModal(false)}>{addModal?.data ? 'Edit' : 'Add'}</ModalHeader>
                <AddModal fetchData={fetchData} form={addModal} setModal={setAddModal}/>
            </Modal>
            <div className="container-fluid">
                <Breadcrumbs breadcrumbItem={`Users (${total})`}/>
                <Row>
                    <Col sm={12}>
                        <Card>
                            {isFetching ? (
                                <div className="d-flex justify-content-center p-5">
                                    <Spinner color="primary" size="lg"/>
                                </div>
                            ) : (
                                <CardBody>
                                    <Can action="user_add">
                                        <Button
                                            type="button"
                                            color="success"
                                            className="btn-label mb-3"
                                            onClick={() => setAddModal({status: true})}
                                        >
                                            <i className="bx bx-plus label-icon"/> Add
                                        </Button>
                                    </Can>
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Surname</th>
                                                <th>Email</th>
                                                <th>Role</th>
                                                <th>Status</th>
                                                <th>Created at</th>
                                                <th>Updated at</th>
                                                <th>Actions</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {data.map(item => (
                                                <tr key={item.id}>
                                                    <td>{item.index}</td>
                                                    <td>{item.name}</td>
                                                    <td>{item.surname}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.role}</td>
                                                    <td>{item.status}</td>
                                                    <td>{item.createdAt}</td>
                                                    <td>{item.updatedAt}</td>
                                                    <td>{item.actions}</td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <CustomPagination limit={limit} setLimit={setLimit} total={total} setPage={setPage}
                                                      page={page}/>
                                </CardBody>
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>

    )
}

export default Users
