import axios from './index'

const path = 'users'

class User {
    get = (params) => axios.get(`${path}/index`, {params})
    add = (data) => axios.post(`${path}/store`, data)
    getById = (id) => axios.get(`${path}/show/${id}`)
    update = (data, id) => axios.post(`${path}/update/${id}`, data)
    selectList = () => axios.post(`${path}/selectList`)
    delete = (id) => axios.delete(`${path}/delete/${id}`)
}

export default new User()
