import {Button, Input, Label, Spinner} from "reactstrap";
import {Controller} from "react-hook-form";
import FormHelper from "../../helpers/form";

const Excerpt = ({control, watch, loaders, generateExcerpt, errors}) => {
    const selectedLang = watch('selectedLang')?.value
    const name = `${selectedLang}.excerpt`
    const title = watch(`${selectedLang}.title`)
    return (
        <>
            <div className="mb-3">
                <Label for="Excerpt">Excerpt</Label>
                <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                    <Input
                        type="textarea"
                        id={name}
                        className={`form-control ${errors?.[selectedLang]?.excerpt && 'is-invalid'}`}
                        value={value}
                        onChange={onChange}
                        rows={5}
                    />
                )} name={name} control={control}/>
                {FormHelper.generateFormFeedback(errors, name)}
            </div>
            <div className="d-flex justify-content-end">
                <Button type="button" color="primary" disabled={!title || loaders?.generateExcerpt}
                        onClick={generateExcerpt}>
                    {loaders?.generateExcerpt ?
                        <Spinner size="sm" color="light"/> : 'Generate excerpt'}
                </Button>
            </div>
        </>
    )
}

export default Excerpt
