import Breadcrumbs from "components/Common/Breadcrumb";
import {
    Badge,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Collapse,
    Input,
    Label,
    Modal,
    ModalHeader,
    Progress,
    Row,
    Spinner,
    UncontrolledTooltip
} from "reactstrap";
import {useEffect, useState} from "react";
import ConfirmModal from "../../components/Common/ConfirmModal";
import Api from 'api/articles'
import {Controller, useForm} from "react-hook-form";
import Form from "../../helpers/form";
import Filter from "../../components/Filter";
import Can from "../../components/Common/Can";
import CustomPagination from "../../components/CustomPagination";
import NotFound from "../../assets/images/notFound.svg";
import {Link} from "react-router-dom";
import CustomSelect from "../../components/CustomSelect";
import Category from "../../api/category";
import Tags from "../../api/tags";
import ThirdParty from "../../api/third-party";
import {articleProgressStatuses, articleStatuses} from "../../data/statuses";
import CustomDate from "../../components/CustomDate";
import Translate from "./Translate";

const Articles = () => {
    const title = 'Articles'
    const defaultValues = {
        keyword: '',
        title: '',
        categories: [],
        tags: [],
        languages: [],
        status: null,
        progress: null,
        date: null
    }
    document.title = title
    const permissionKeys = {
        add: 'article_add',
        edit: 'article_edit',
        edit_status: 'article_edit_status',
        translate: 'article_translate',
        delete: 'article_delete'
    }
    const [data, setData] = useState([])
    const {handleSubmit, control, reset, getValues} = useForm({defaultValues})
    const [isFetching, setIsFetching] = useState(true)
    const [confirmModal, setConfirmModal] = useState(false)
    const [multiDeleteModal, setMultiDeleteModal] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [total, setTotal] = useState(0)
    const [categories, setCategories] = useState([])
    const [tags, setTags] = useState([])
    const [languages, setLanguages] = useState([])
    const [selectedArticles, setSelectedArticles] = useState([])
    const [deleteLoader, setDeleteLoader] = useState(false)
    const [translateForm, setTranslateForm] = useState({status: false})
    const [progressBars, setProgressBars] = useState([])
    const [showProgress, setShowProgress] = useState(false)
    const [progressIsFetching, setProgressIsFetching] = useState(false)


    const deleteData = async () => {
        await Api.delete({ids: [confirmModal]})
        fetchData()
    }

    const deleteMultiple = async () => {
        setDeleteLoader(true)
        await Api.delete({ids: selectedArticles})
        fetchData()
        setDeleteLoader(false)
    }

    const fetchData = async (p = null) => {
        setIsFetching(true)
        const data = await Api.get(Form.validateApiData({
            page: p || page,
            limit,
            ...Form.validateApiData(getValues()),
            categories: getValues()?.categories?.map(item => item?.value),
            tags: getValues()?.tags?.map(item => item?.value),
            languages: getValues()?.languages?.map(item => item?.value),
            date: Form.convertFormDate(getValues()?.date)
        }))
        setData(data?.data?.articles)
        setTotal(data?.meta?.total)
        setIsFetching(false)
    }

    const selectAll = () => {
        if (selectedArticles.length === data.length) {
            setSelectedArticles([])
            return
        }
        setSelectedArticles(data?.map(item => item.id))
    }

    const selectArticle = (id) => {
        if (selectedArticles.includes(id)) {
            setSelectedArticles(prev => ([
                ...prev.filter(item => item !== id)
            ]))
            return
        }
        setSelectedArticles(prev => ([
            ...prev,
            id
        ]))
    }

    const submit = () => {
        fetchData(1)
        setPage(1)
    }

    const updateStatus = async (id, status) => {
        await Api.updateStatus(id, status === 1 ? 0 : 1)
        fetchData()
    }

    const resetForm = () => {
        reset(defaultValues)
        setPage(1)
        fetchData(1)
    }

    const getProgressStatus = status => {
        const color = status === 1 ? 'success' : status === 2 ? 'warning' : 'danger'
        const text = status === 1 ? 'Competed' : status === 2 ? 'In progress' : 'Fail'
        return <Badge color={color}>{text}</Badge>
    }

    const fetchCategories = async () => {
        const {data} = await Category.getSelect()
        setCategories(data)
    }

    const fetchTags = async () => {
        const {data} = await Tags.getSelect()
        setTags(data)
    }

    const fetchLanguages = async () => {
        const {data} = await ThirdParty.getLanguages('en')
        setLanguages(data?.languages?.map(item => {
            return {
                value: item?.language,
                label: item?.name
            }
        }))
    }

    const fetchProgress = async () => {
        setProgressIsFetching(true)
        const {data} = await Api.getProgress()
        setProgressBars(data?.groups)
        setProgressIsFetching(false)
    }

    useEffect(() => {
        fetchCategories()
        fetchTags()
        fetchLanguages()
        fetchProgress()
    }, [])


    useEffect(() => {
        fetchData()
    }, [page, limit])

    return (
        <div className="page-content">
            <ConfirmModal active={confirmModal} setActive={setConfirmModal} callback={deleteData}/>
            <ConfirmModal active={multiDeleteModal} setActive={setMultiDeleteModal} callback={deleteMultiple}/>
            <Modal size="lg" className="modal-dialog-centered" isOpen={translateForm?.status}
                   toggle={() => setTranslateForm({})}>
                <ModalHeader
                    toggle={() => setTranslateForm({})}>Translates</ModalHeader>
                <Translate form={translateForm} fetchData={fetchData} setForm={setTranslateForm}/>
            </Modal>
            <div className="container-fluid">
                <Breadcrumbs breadcrumbItem={`${title} (${total})`}/>
                <Row>
                    <Filter submit={handleSubmit(submit)} reset={resetForm} isFetching={isFetching}>
                        <Col sm={12} md={3}>
                            <div className="mt-3">
                                <Label for="keyword">Keyword</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <Input value={value} onChange={onChange} placeholder=""/>
                                )} control={control} name="keyword"/>
                            </div>
                        </Col>
                        <Col sm={12} md={3}>
                            <div className="mt-3">
                                <Label for="title">Title</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <Input value={value} onChange={onChange} placeholder=""/>
                                )} control={control} name="title"/>
                            </div>
                        </Col>
                        <Col sm={12} md={3}>
                            <div className="mt-3">
                                <Label for="categories">Categories</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <CustomSelect isClearable isMulti options={categories} value={value}
                                                  onChange={onChange}
                                                  name="categories"/>
                                )} control={control} name="categories"/>
                            </div>
                        </Col>
                        <Col sm={12} md={3}>
                            <div className="mt-3">
                                <Label for="tags">Tags</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <CustomSelect isClearable isMulti options={tags} value={value} onChange={onChange}
                                                  name="tags"/>
                                )} control={control} name="tags"/>
                            </div>
                        </Col>
                        <Col sm={12} md={3}>
                            <div className="mt-3">
                                <Label for="languages">Lanugages</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <CustomSelect isClearable isMulti options={languages} value={value}
                                                  onChange={onChange}
                                                  name="languages"/>
                                )} control={control} name="languages"/>
                            </div>
                        </Col>
                        <Col sm={12} md={3}>
                            <div className="mt-3">
                                <Label for="status">Status</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <CustomSelect isClearable options={articleStatuses} value={value}
                                                  onChange={onChange}
                                                  name="status1"/>
                                )} control={control} name="status"/>
                            </div>
                        </Col>
                        <Col sm={12} md={3}>
                            <div className="mt-3">
                                <Label for="progress">Progress</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <CustomSelect isClearable options={articleProgressStatuses} value={value}
                                                  onChange={onChange}
                                                  name="progress"/>
                                )} control={control} name="progress"/>
                            </div>
                        </Col>
                        <Col sm={12} md={3}>
                            <div className="mt-3">
                                <Label for="date">Date</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <CustomDate value={value} onChange={onChange}/>
                                )} control={control} name="date"/>
                            </div>
                        </Col>
                    </Filter>
                    {progressBars?.length > 0 && (
                        <Col sm={12}>
                            <Card>
                                <CardHeader className="cursor-pointer bg-white"
                                            onClick={() => setShowProgress(!showProgress)}>
                                    <CardTitle className="mb-0">
                                        Bulk articles
                                        <Badge
                                            className="ms-2"
                                            color="success">{progressBars.length}
                                        </Badge>
                                        <Button className="ms-2" size="sm" disabled={progressIsFetching} color="primary"
                                                type="button"
                                                onClick={() => {
                                                    fetchProgress()
                                                    fetchData(1)
                                                    setPage(1)
                                                }} id="refresh">
                                            {progressIsFetching ? <Spinner size="sm" color="light"/> :
                                                <i className="bx bx-rotate-right"/>}
                                        </Button>
                                        <UncontrolledTooltip target="refresh"
                                                             placement="bottom">
                                            Refresh
                                        </UncontrolledTooltip>
                                    </CardTitle>
                                </CardHeader>
                                <Collapse isOpen={showProgress}>
                                    <CardBody>
                                        <div className="d-flex flex-column gap-2">
                                            {progressBars.map(item => (
                                                <div className="d-flex gap-2" key={item.id}>
                                                    <Progress
                                                        striped
                                                        animated
                                                        style={{height: '16px'}}
                                                        color="success"
                                                        className="w-100"
                                                        value={item?.completed}
                                                        max={item?.total}>
                                                        {((item?.completed / item?.total) * 100).toFixed(2)}%
                                                    </Progress>
                                                    <div style={{minWidth: '50px'}}>
                                                        {item?.completed} / {item?.total}
                                                    </div>
                                                </div>

                                            ))}
                                        </div>
                                    </CardBody>
                                </Collapse>
                            </Card>
                        </Col>
                    )}
                    <Col sm={12}>
                        <Card>
                            {isFetching ? (
                                <div className="d-flex justify-content-center p-5">
                                    <Spinner color="primary" size="lg"/>
                                </div>
                            ) : (
                                <CardBody>
                                    <div className="d-flex gap-2">
                                        <Can action={permissionKeys.add}>
                                            <Button
                                                tag={Link}
                                                to={'/articles/create'}
                                                type="button"
                                                color="success"
                                                className="btn-label mb-3"
                                            >
                                                <i className="bx bx-plus label-icon"/> Add
                                            </Button>
                                        </Can>
                                        <Can action={permissionKeys.delete}>
                                            <Button
                                                onClick={() => setMultiDeleteModal(true)}
                                                type="button"
                                                disabled={deleteLoader || !selectedArticles.length}
                                                color="danger"
                                                className="btn-label mb-3"
                                            >
                                                <i
                                                    className="bx bx-trash label-icon"/>
                                                {deleteLoader ? <Spinner size="sm" color="light"/> : 'Delete'}
                                            </Button>
                                        </Can>
                                        <Can action={permissionKeys.translate}>
                                            <Button
                                                onClick={() => setTranslateForm({
                                                    status: true,
                                                    data: data?.filter(item => selectedArticles.includes(item.id))
                                                })}
                                                type="button"
                                                disabled={!selectedArticles.length}
                                                color="primary"
                                                className="btn-label mb-3"
                                            >
                                                <i
                                                    className="fa fa-language label-icon"/> Translate
                                            </Button>
                                        </Can>
                                    </div>
                                    <div className="table-responsive">
                                        {data?.length > 0 ? (
                                            <>
                                                <div className="table-responsive">
                                                    <table className="table table-bordered">
                                                        <thead>
                                                        <tr>
                                                            <th>
                                                                №
                                                                <Label className="d-flex gap-1">
                                                                    <div className="form-check">
                                                                        <input type="checkbox"
                                                                               checked={selectedArticles.length === data?.length && data.length}
                                                                               onChange={selectAll}
                                                                               disabled={!data?.length}
                                                                               className="form-check-input"/>
                                                                    </div>
                                                                </Label>
                                                            </th>
                                                            <th>Keyword</th>
                                                            <th>Title</th>
                                                            <th>Photos</th>
                                                            <th>Categories</th>
                                                            <th>Tags</th>
                                                            <th>Status</th>
                                                            <th>Progress</th>
                                                            <th>Created at</th>
                                                            <th>Updated at</th>
                                                            <th>Actions</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {data?.map((item, index) => (
                                                            <tr key={item.id}>
                                                                <td>
                                                                    {index + 1}
                                                                    <Label className="d-flex gap-1">
                                                                        <div className="form-check">
                                                                            <input type="checkbox"
                                                                                   value={1}
                                                                                   checked={selectedArticles.includes(item.id)}
                                                                                   onChange={() => selectArticle(item.id)}
                                                                                   name={`checkbox-${item.id}`}
                                                                                   className="form-check-input"/>
                                                                        </div>
                                                                    </Label>
                                                                </td>
                                                                <td>{item.keyword}</td>
                                                                <td>
                                                                    <a target="_blank"
                                                                       href={Form.generateSiteUrl(`en/blogs/${item?.en_slug?.name}`)}>{item.title}</a>
                                                                </td>
                                                                <td>
                                                                    <img width="100" height="100"
                                                                         src={item?.photos?.[0]?.photo}
                                                                         alt={item.title}/>
                                                                </td>
                                                                <td>{item.en_categories?.map(x => x?.name)?.join(', ')}</td>
                                                                <td>{item.en_tags?.map(x => x?.name)?.join(', ')}</td>
                                                                <td>
                                                                    <Badge
                                                                        className="cursor-pointer"
                                                                        onClick={() => updateStatus(item.id, item.status)}
                                                                        color={item.status ? 'success' : 'danger'}>
                                                                        {item.status ? 'Active' : 'Deactive'}
                                                                    </Badge>
                                                                </td>
                                                                <td>
                                                                    {getProgressStatus(item.progress)}
                                                                </td>
                                                                <td>{Form.getDate(item.created_at)}</td>
                                                                <td>{Form.getDate(item.updated_at)}</td>
                                                                <td>
                                                                    <div className="d-flex align-items-center gap-1">
                                                                        <Can action={permissionKeys.edit}>
                                                                            <Button size="sm" color="success"
                                                                                    id={`edit-${item.id}`} tag={Link}
                                                                                    to={`/articles/edit/${item.id}`}>
                                                                                <i className="bx bx-pencil"/>
                                                                            </Button>
                                                                            <UncontrolledTooltip
                                                                                target={`edit-${item.id}`}
                                                                                placement="bottom">
                                                                                Edit
                                                                            </UncontrolledTooltip>
                                                                        </Can>
                                                                        <Can action={permissionKeys.delete}>
                                                                            <Button size="sm" color="danger"
                                                                                    id={`delete-${item.id}`}
                                                                                    onClick={() => setConfirmModal(item.id)}>
                                                                                <i className="bx bx-trash"/>
                                                                            </Button>
                                                                            <UncontrolledTooltip
                                                                                target={`delete-${item.id}`}
                                                                                placement="bottom">
                                                                                Delete
                                                                            </UncontrolledTooltip>
                                                                        </Can>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <CustomPagination page={page} limit={limit} setLimit={setLimit}
                                                                  setPage={setPage} total={total}/>
                                            </>
                                        ) : (
                                            <div
                                                className="h-50 d-flex justify-content-center align-items-center">
                                                <div>
                                                    <img src={NotFound} alt="Not found"/>
                                                    <b>Data not found</b>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Articles
