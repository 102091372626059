import {Button, Card, CardBody, Input, Label, Spinner} from "reactstrap";
import {Controller} from "react-hook-form";
import FormHelper from "../../helpers/form";
import {useParams} from "react-router-dom";

const Topic = ({control, errors, watch, loaders, generateAll, generateTitle}) => {
    const {id} = useParams()
    const keyword = watch('keyword')

    return (
        <Card>
            <h5 className="card-header bg-transparent border-bottom text-uppercase">
                Topic
            </h5>
            <CardBody>
                <div className="mb-3">
                    <Label for="keyword">Keyword</Label>
                    <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                        <Input
                            disabled={id}
                            type="textarea"
                            id="keyword"
                            className={`form-control ${errors?.keyword && 'is-invalid'}`}
                            value={value}
                            onChange={onChange}
                            rows={5}
                        />
                    )} name="keyword" control={control}/>
                    {FormHelper.generateFormFeedback(errors, 'keyword')}
                </div>
                {!id && (
                    <div className="d-flex flex-column gap-2">
                        <Button disabled={!watch('keyword') || loaders?.generateTitle} onClick={generateTitle}
                                type="button"
                                color="primary">
                            {loaders?.generateTitle ?
                                <Spinner size="sm" color="light"/> : 'Generate title'}
                        </Button>
                        <Button disabled={!keyword || loaders?.generateAll}
                                onClick={generateAll} type="button"
                                color="primary">
                            {loaders?.generateAll ?
                                <Spinner size="sm" color="light"/> : 'Generate all'}
                        </Button>
                    </div>
                )}
            </CardBody>
        </Card>
    )
}

export default Topic
