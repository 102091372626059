const categoryTypes = {
    main: {
        value: 1,
        label: 'Main'
    },
    chatGPT: {
        value: 2,
        label: 'ChatGPT'
    }
}

const categoryTypesArr = Object.values(categoryTypes)

export {
    categoryTypes,
    categoryTypesArr
}
