import React from "react";
import {Navigate} from "react-router-dom";

// Pages Component
// File Manager
// Profile
// Pages Calendar
// //Tasks
// //Projects
// //Ecommerce Pages
//Email
//Invoices
// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

//  // Inner Authentication
// Dashboard
import Dashboard from "../pages/Dashboard/index";

//Crypto
// Charts
// Maps
//Icons
//Tables
//Blog
//Job
// Forms
//Ui
//Pages
//Contacts
import Users from "../pages/Users";
import UserProfile from "../pages/Authentication/user-profile";
import Permissions from "../pages/Permissions";
import Tags from "../pages/Tags";
import Categories from "../pages/Categories";
import Articles from "../pages/Articles";
import ArticleForm from "../pages/ArticleForm";
import BulkArticle from "../pages/BulkArticle";

const routeItem = (path, component, can) => {
    return {path, component, can}
}

const authProtectedRoutes = [
    routeItem('/dashboard', <Dashboard/>, null),
    routeItem('/users', <Users/>, 'user_view'),
    routeItem('/profile', <UserProfile/>, null),
    routeItem('/articles', <Articles/>, 'article_view'),
    routeItem('/articles/create', <ArticleForm/>, 'article_add'),
    routeItem('/articles/edit/:id', <ArticleForm/>, 'article_edit'),
    routeItem('/articles/create/bulk', <BulkArticle/>, 'article_add_bulk'),
    routeItem('/categories', <Categories/>, 'category_view'),
    routeItem('/tags', <Tags/>, 'tag_view'),
    routeItem('/roles', <Permissions/>, 'role_view'),
    {
        path: "/",
        exact: true,
        component: <Navigate to="/dashboard"/>,
    },
];

const publicRoutes = [
    {path: "/logout", component: <Logout/>},
    {path: "/login", component: <Login/>},
];

export {authProtectedRoutes, publicRoutes};
