import React, {useEffect} from "react";
import {Navigate} from "react-router-dom";
import Auth from 'api/auth'
import LocalStorageManager from "../helpers/localStorageManager";
import {toast} from "react-toastify";

const Authmiddleware = (props) => {
    const actions = LocalStorageManager.get('user')?.can
    const token = localStorage.getItem("token")
    if (!token) {
        return (
            <Navigate to={{pathname: "/login", state: {from: props.location}}}/>
        );
    }
    if (!actions) {
        toast.error('You have not any permission')
        return <Navigate to={{pathname: "/login",}}/>

    }

    const can = props.route.can
    if (Array.isArray(can) && !['/dashboard', '/profile'].includes(props.route.path) && can.some(item => !actions[item])) {
        return <Navigate to={{pathname: "/dashboard",}}/>
    } else if (!Array.isArray(can) && !actions?.[can] && !['/dashboard', '/profile'].includes(props.route.path)) {
        return <Navigate to={{pathname: "/dashboard",}}/>
    }

    const auth = async () => {
        const {data} = await Auth.auth()
        LocalStorageManager.set('user', data?.user)
    }

    useEffect(() => {
        if (token) {
            auth()
        }
    }, [])

    return (<React.Fragment>
        {props.children}
    </React.Fragment>);
};

export default Authmiddleware;
