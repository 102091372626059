import Breadcrumbs from "components/Common/Breadcrumb";
import {
    Button,
    Card,
    CardBody,
    Col,
    Input,
    Label,
    Modal,
    ModalHeader,
    Row,
    Spinner,
    UncontrolledTooltip
} from "reactstrap";
import {useEffect, useState} from "react";
import ConfirmModal from "../../components/Common/ConfirmModal";
import Add from "./Add";
import Api from 'api/category'
import CustomPagination from "../../components/CustomPagination";
import {Controller, useForm} from "react-hook-form";
import Form from "../../helpers/form";
import Can from "../../components/Common/Can";
import Filter from "../../components/Filter";
import TranslatedData from "../../components/Common/TranslatedData";
import CustomSelect from "../../components/CustomSelect";
import {categoryTypesArr} from "../../data/categoryTypes";
import NotFound from '../../assets/images/notFound.svg'

const Tags = () => {
    const title = 'Categories'
    const defaultValues = {
        name: '',
        type: null
    }
    document.title = title
    const permissionKeys = {
        add: 'category_add',
        edit: 'category_edit',
        delete: 'category_delete'
    }
    const [data, setData] = useState([])
    const {handleSubmit, control, reset, getValues} = useForm({defaultValues})
    const [isFetching, setIsFetching] = useState(true)
    const [form, setForm] = useState({})
    const [translateForm, setTranslateForm] = useState({})
    const [confirmModal, setConfirmModal] = useState(false)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [total, setTotal] = useState(0)


    const deleteData = async () => {
        await Api.delete(confirmModal)
        fetchData()
    }

    const fetchData = async (p = null) => {
        setIsFetching(true)
        const data = await Api.get(Form.validateApiData({
            page: p || page,
            limit,
            ...Form.validateApiData(getValues())
        }))
        setData(data?.data?.categories)
        setTotal(data?.meta?.total)
        setIsFetching(false)
    }

    const submit = () => {
        fetchData(1)
        setPage(1)
    }

    const resetForm = () => {
        reset(defaultValues)
        setPage(1)
        fetchData(1)
    }

    useEffect(() => {
        fetchData()
    }, [page, limit])

    return (
        <div className="page-content">
            <ConfirmModal active={confirmModal} setActive={setConfirmModal} callback={deleteData}/>
            <Modal className="modal-dialog-centered" isOpen={form?.status} toggle={() => setForm({})}>
                <ModalHeader
                    toggle={() => setForm({})}>{form?.data ? 'Edit' : 'Add'}</ModalHeader>
                <Add fetchData={fetchData} form={form} setForm={setForm}/>
            </Modal>
            <Modal className="modal-dialog-centered" isOpen={translateForm?.status} toggle={() => setTranslateForm({})}>
                <ModalHeader
                    toggle={() => setTranslateForm({})}>Translates</ModalHeader>
                <TranslatedData form={translateForm} setForm={setTranslateForm}/>
            </Modal>
            <div className="container-fluid">
                <Breadcrumbs breadcrumbItem={`${title} (${total})`}/>
                <Row>
                    <Filter submit={handleSubmit(submit)} reset={resetForm} isFetching={isFetching}>
                        <Col sm={12} md={4}>
                            <div className="mt-3">
                                <Label for="name">Name</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <Input value={value} onChange={onChange} placeholder=""/>
                                )} control={control} name="name"/>
                            </div>
                        </Col>
                        <Col sm={12} md={4}>
                            <div className="mt-3">
                                <Label for="type">Type</Label>
                                <Controller render={({field: {value, onChange}}) => (
                                    <CustomSelect onChange={onChange} value={value} name="type"
                                                  options={categoryTypesArr}/>
                                )} control={control} name="type"/>
                            </div>
                        </Col>
                    </Filter>
                    <Col sm={12}>
                        <Card>
                            {isFetching ? (
                                <div className="d-flex justify-content-center p-5">
                                    <Spinner color="primary" size="lg"/>
                                </div>
                            ) : (
                                <CardBody>
                                    <Can action={permissionKeys.add}>
                                        <Button
                                            onClick={() => setForm({status: true})}
                                            type="button"
                                            color="success"
                                            className="btn-label mb-3"
                                        >
                                            <i className="bx bx-plus label-icon"/> Add
                                        </Button>
                                    </Can>
                                    {data?.length > 0 ? (
                                        <>
                                            <div className="table-responsive">
                                                <table className="table table-bordered">
                                                    <thead>
                                                    <tr>
                                                        <th>№</th>
                                                        <th>Name</th>
                                                        <th>Type</th>
                                                        <th>Image</th>
                                                        <th>Created at</th>
                                                        <th>Actions</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {data?.map((item, index) => (
                                                        <tr key={item.id}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.name}</td>
                                                            <td>{categoryTypesArr.find(type => type.value === item.type).label}</td>
                                                            <td>
                                                                {item?.image && <a target="_blank" href={item.image}>
                                                                    <img src={item.image} width="100%" height="100px"
                                                                         style={{objectFit: 'contain'}}
                                                                         alt={item.name}/>
                                                                </a>}
                                                            </td>
                                                            <td>{Form.getDate(item.created_at)}</td>
                                                            <td>
                                                                <div className="d-flex align-items-center gap-1">
                                                                    <Can action={permissionKeys.edit}>
                                                                        <Button size="sm" color="success"
                                                                                id={`edit-${item.id}`}
                                                                                onClick={() => setForm({
                                                                                    status: true,
                                                                                    data: item
                                                                                })}>
                                                                            <i className="bx bx-pencil"/>
                                                                        </Button>
                                                                        <UncontrolledTooltip
                                                                            target={`edit-${item.id}`}
                                                                            placement="bottom">
                                                                            Edit
                                                                        </UncontrolledTooltip>
                                                                    </Can>
                                                                    <Button size="sm" color="primary"
                                                                            id={`translates-${item.id}`}
                                                                            onClick={() => setTranslateForm({
                                                                                status: true,
                                                                                data: item
                                                                            })}>
                                                                        <i className="fa fa-language"/>
                                                                    </Button>
                                                                    <UncontrolledTooltip
                                                                        target={`translates-${item.id}`}
                                                                        placement="bottom">
                                                                        Translates
                                                                    </UncontrolledTooltip>
                                                                    <Can action={permissionKeys.delete}>
                                                                        <Button size="sm" color="danger"
                                                                                id={`delete-${item.id}`}
                                                                                onClick={() => setConfirmModal(item.id)}>
                                                                            <i className="bx bx-trash"/>
                                                                        </Button>
                                                                        <UncontrolledTooltip
                                                                            target={`delete-${item.id}`}
                                                                            placement="bottom">
                                                                            Delete
                                                                        </UncontrolledTooltip>
                                                                    </Can>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <CustomPagination page={page} limit={limit} setLimit={setLimit}
                                                              setPage={setPage} total={total}/>
                                        </>
                                    ) : (
                                        <div
                                            className="h-50 d-flex justify-content-center align-items-center">
                                            <div>
                                                <img src={NotFound} alt="Not found"/>
                                                <b>Data not found</b>
                                            </div>
                                        </div>
                                    )}
                                </CardBody>
                            )}
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Tags
