import Select from "react-select";
import FormHelper from "../../helpers/form";

const CustomSelect = ({errors, options, name, value, onChange, ...props}) => {
    return (
        <>
            <Select
                {...props}
                options={options}
                type="text"
                name={name}
                placeholder={props.placeholder || ''}
                id={name}
                value={value}
                onChange={onChange}
                className={`${errors?.[name] && 'is-invalid'} ${props.className}`}
            />
            {FormHelper.generateFormFeedback(errors, name)}
        </>
    )
}

export default CustomSelect
