import axios from 'axios'
import {toast} from "react-toastify";

const instance = axios.create({
    baseURL: 'https://service.newtimes.az/api/service/'
})

instance.interceptors.response.use(response => {
    if (response.config.method !== 'get' && !response.config.skipToast) {
        toast.success(response.data.message)
    }
    return response?.data
}, error => {
    if (error.response.status === 401) {
        window.location.href = '/login'
    }
    return Promise.reject(error)
})


instance.interceptors.request.use(config => {
    const token = localStorage.getItem('token')
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
})

class ThirdParty {
    searchPhoto = params => instance.get(`search-photo/small`, {params})
    getLanguages = (target, key) => instance.get(`languages/${target}`)
    searchContent = data => instance.post(`search-content`, data, {
        skipToast: true
    })
}

export default new ThirdParty()
