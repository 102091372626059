import {Controller} from "react-hook-form";
import {Button, Input, Label, Spinner} from "reactstrap";
import FormHelper from "../../helpers/form";
import CustomSelect from "../../components/CustomSelect";

const Sections = ({control, watch, errors, generateSections, loaders}) => {
    const selectedLang = watch('selectedLang')?.value
    const name = `${selectedLang}.section`
    const title = watch(`${selectedLang}.title`)

    return (
        <div>
            <div className="mb-3">
                <Label for="section_count">Section count</Label>
                <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                    <CustomSelect options={Array.from({length: 50}).map((item, index) => {
                        return {value: index + 1, label: index + 1}
                    })} errors={errors} value={value} onChange={onChange} name="section_count"/>
                )} name="section_count" control={control}/>
                {FormHelper.generateFormFeedback(errors, 'section_count')}
            </div>
            <div className="mb-3">
                <Label for={name}>Sections</Label>
                <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                    <Input
                        type="textarea"
                        id={name}
                        className={`form-control ${errors?.[selectedLang]?.section && 'is-invalid'}`}
                        value={value}
                        onChange={onChange}
                        rows={5}
                    />
                )} name={name} control={control}/>
                {FormHelper.generateFormFeedback(errors, name)}
            </div>
            <div className="d-flex justify-content-end">
                <Button type="button" color="primary" disabled={!title || loaders?.generateSections}
                        onClick={generateSections}>
                    {loaders?.generateSections ?
                        <Spinner size="sm" color="light"/> : 'Generate sections'}
                </Button>
            </div>
        </div>
    )
}

export default Sections
