import {Card, CardBody, Input, Label} from "reactstrap";
import {Controller} from "react-hook-form";

const Prompts = ({control}) => {
    return (
        <Card>
            <h5 className="card-header bg-transparent border-bottom text-uppercase">Prompts</h5>
            <CardBody>
                <div className="mb-3">
                    <Label for="title_prompt">Title prompt</Label>
                    <Controller render={({field: {value, onChange}}) => (
                        <Input
                            type="textarea"
                            rows={5}
                            id="title_prompt"
                            className="form-control"
                            value={value}
                            onChange={onChange}
                        />
                    )} name="title_prompt" control={control}/>
                </div>
                <div className="mb-3">
                    <Label for="section_prompt">Sections prompt</Label>
                    <Controller render={({field: {value, onChange}}) => (
                        <Input
                            type="textarea"
                            rows={5}
                            id="section_prompt"
                            className="form-control"
                            value={value}
                            onChange={onChange}
                        />
                    )} name="section_prompt" control={control}/>
                </div>
                <div className="mb-3">
                    <Label for="content_prompt">Content prompt</Label>
                    <Controller render={({field: {value, onChange}}) => (
                        <Input
                            type="textarea"
                            rows={5}
                            id="content_prompt"
                            className="form-control"
                            value={value}
                            onChange={onChange}
                        />
                    )} name="content_prompt" control={control}/>
                </div>
                <div className="mb-3">
                    <Label for="faq_prompt">FAQ prompt</Label>
                    <Controller render={({field: {value, onChange}}) => (
                        <Input
                            type="textarea"
                            rows={5}
                            id="faq_prompt"
                            className="form-control"
                            value={value}
                            onChange={onChange}
                        />
                    )} name="faq_prompt" control={control}/>
                </div>
                <div className="mb-3">
                    <Label for="excerpt_prompt">Excerpt prompt</Label>
                    <Controller render={({field: {value, onChange}}) => (
                        <Input
                            type="textarea"
                            rows={5}
                            id="excerpt_prompt"
                            className="form-control"
                            value={value}
                            onChange={onChange}
                        />
                    )} name="excerpt_prompt" control={control}/>
                </div>
            </CardBody>
        </Card>
    )
}

export default Prompts
