import Breadcrumbs from "../../components/Common/Breadcrumb";
import {Controller, useForm} from "react-hook-form";
import ChatGptStyles from "../../data/chatGptStyles.json";
import ChatGptTones from "../../data/chatGptTones.json";
import ChatGptPrompts from "../../data/chatGptPrompts.json";
import ChatGptModels from "../../data/chatGptModels.json";
import {Button, Card, CardBody, Col, Input, Label, Row, Spinner} from "reactstrap";
import Styles from "../ArticleForm/Styles";
import Categories from "../ArticleForm/Categories";
import Tags from "../ArticleForm/Tags";
import Models from "../ArticleForm/Models";
import Prompts from "../ArticleForm/Prompts";
import Form from "../../helpers/form";
import FormHelper from "../../helpers/form";
import {useState} from "react";
import {toast} from "react-toastify";
import CustomSelect from "../../components/CustomSelect";
import Helpler from "../../helpers/Helpler";
import Articles from "../../api/articles";
import {useNavigate} from "react-router-dom";

const BulkArticle = () => {
    const {control, handleSubmit, formState: {errors}} = useForm({
        defaultValues: {
            ...ChatGptPrompts,
            writing_style: ChatGptStyles.creative,
            writing_tone: ChatGptTones.neutral,
            chatgpt_model: ChatGptModels["gpt-3.5-turbo"],
            section_count: {value: 5, label: 5},
            paragraph_count: {value: 3, label: 3},
            selectedLang: {value: 'en', label: 'English'}
        }
    })

    const [loader, setLoader] = useState(false)
    const navigate = useNavigate()

    const submit = async (values) => {
        setLoader(true)
        try {
            let formData = {
                ...values,
                main_categories: values?.main_categories?.map(item => item?.value),
                tags: values?.tags?.map(item => item?.value),
                section_count: values?.section_count?.value,
                paragraph_count: values?.paragraph_count?.value,
                writing_style: values?.writing_style?.value,
                writing_tone: values?.writing_tone?.value,
                chatgpt_model: values?.chatgpt_model?.value,
                keywords: values?.keywords?.split('\n')?.filter(item => item),
                categories_prompt: ChatGptPrompts.categories_prompt.replace('$count', Helpler.generateRandomNumber(1, 5)),
                tags_prompt: ChatGptPrompts.tags_prompt.replace('$count', Helpler.generateRandomNumber(1, 20)),
                title: ChatGptPrompts.title_prompt
                    .replace('$language', 'English')
                    .replace('$writingStyle', values?.writing_style?.value)
                    .replace('$writingTone', values?.writing_tone?.value),
                section: ChatGptPrompts.section_prompt
                    .replace('$sectionsCount', values?.section_count?.value)
                    .replace('$language', 'English')
                    .replace('$writingStyle', values?.writing_style?.value)
                    .replace('$writingTone', values?.writing_tone?.value),
                content: ChatGptPrompts.content_prompt
                    .replace('$paragraphsPerSection', values?.paragraph_count?.value)
                    .replace('$language', 'English')
                    .replace('$writingStyle', values?.writing_style?.value)
                    .replace('$writingTone', values?.writing_tone?.value),
                faq: ChatGptPrompts.faq_prompt
                    .replace('$language', 'English')
                    .replace('$writingStyle', values?.writing_style?.value)
                    .replace('$writingTone', values?.writing_tone?.value),
                excerpt: ChatGptPrompts.excerpt_prompt
                    .replace('$language', 'English')
                    .replace('$writingStyle', values?.writing_style?.value)
                    .replace('$writingTone', values?.writing_tone?.value),
            }
            await Articles.bulkCreate(formData)
            navigate('/articles')
        } catch (e) {
            toast.error('Error')
        } finally {
            setLoader(false)
        }
    }


    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs breadcrumbItem={`Bulk Article`}/>
                <form onSubmit={handleSubmit(submit)}>
                    <Row>
                        <Col sm={12} md={8}>
                            <Card>
                                <h5 className="card-header bg-transparent border-bottom text-uppercase">Parameters</h5>
                                <CardBody>
                                    <Categories control={control} errors={errors}/>
                                    <Tags errors={errors} control={control}/>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <div className="mb-3">
                                        <Label for="section_count">Section count</Label>
                                        <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                                            <CustomSelect options={Array.from({length: 50}).map((item, index) => {
                                                return {value: index + 1, label: index + 1}
                                            })} errors={errors} value={value} onChange={onChange} name="section_count"/>
                                        )} name="section_count" control={control}/>
                                        {FormHelper.generateFormFeedback(errors, '')}
                                    </div>
                                    <div className="mb-3">
                                        <Label for="paragraph_count">Paragraph count</Label>
                                        <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                                            <CustomSelect options={Array.from({length: 50}).map((item, index) => {
                                                return {value: index + 1, label: index + 1}
                                            })} errors={errors} value={value} onChange={onChange}
                                                          name="paragraph_count"/>
                                        )} name="paragraph_count" control={control}/>
                                        {FormHelper.generateFormFeedback(errors, 'paragraph_count')}
                                    </div>
                                </CardBody>
                            </Card>
                            <Models control={control} errors={errors}/>
                            <Card>
                                <CardBody>
                                    <Label for="keywords">Keywords</Label>
                                    <Controller rules={{required: true}} control={control}
                                                render={({field: {value, onChange}}) => (
                                                    <Input
                                                        value={value}
                                                        onChange={onChange}
                                                        type="textarea"
                                                        rows={28}
                                                        name="keywords"
                                                        className={errors?.keywords && 'is-invalid'}/>
                                                )} name="keywords"/>
                                    {Form.generateFormFeedback(errors, 'keywords')}
                                    <div className="d-flex justify-content-end mt-3">
                                        <Button disabled={loader} color="primary" type="submit">
                                            {loader ? <Spinner color="light" size="sm"/> : 'Create'}
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm={12} md={4}>
                            <Styles control={control}/>
                            <Prompts control={control}/>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    )
}

export default BulkArticle
