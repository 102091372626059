import {Button, Input, Label, ModalBody, ModalFooter, Spinner} from "reactstrap";
import {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import FormHelper from "../../helpers/form";
import User from "../../api/user";
import Permission from "../../api/permission";
import CustomSelect from "../../components/CustomSelect";

const AddModal = ({fetchData, setModal, form}) => {
    const statuses = [
        {value: 1, label: 'Active'},
        {value: 2, label: 'Deactive'}
    ]
    const [loader, setLoader] = useState(false)
    const [permissions, setPermissions] = useState([])

    const {handleSubmit, control, setError, reset, formState: {errors}} = useForm({
        defaultValues: {
            status: statuses[0]
        }
    })

    const submit = async (values) => {
        setLoader(true)
        try {
            delete values.photo
            await User[values?.id ? 'update' : 'add'](FormHelper.validateApiData({
                ...values,
                is_admin: true
            }), values?.id)
            fetchData()
            setLoader(false)
            setModal(false)
        } catch (e) {
            FormHelper.setApiErrors(e.response.data, setError)
        } finally {
            setLoader(false)
        }
    }

    const fetchPermissions = async () => {
        const {data} = await Permission.selectList()
        setPermissions(data)
    }

    useEffect(() => {
        const data = form?.data
        if (data && permissions?.length) {
            reset({
                ...data,
                role_id: {
                    value: data?.role_id,
                    label: data?.role_name
                },
                status: statuses.find(item => item.value === data?.status)
            })
        }
    }, [form, permissions])

    useEffect(() => {
        fetchPermissions()
    }, [])

    return (
        <form onSubmit={handleSubmit(submit)}>
            <ModalBody>
                <div className="mb-3">
                    <Label className="form-label">Name</Label>
                    <Controller rules={{
                        required: true
                    }} render={({field: {value, onChange}}) => (
                        <Input
                            name="name"
                            className="form-control"
                            type="text"
                            onChange={onChange}
                            value={value}
                            invalid={errors?.name}
                        />
                    )} name="name" control={control}/>
                    {FormHelper.generateFormFeedback(errors, 'name', errors?.name?.message)}
                </div>
                <div className="mb-3">
                    <Label className="form-label">Surname</Label>
                    <Controller rules={{
                        required: true
                    }} render={({field: {value, onChange}}) => (
                        <Input
                            name="surname"
                            className="form-control"
                            type="text"
                            onChange={onChange}
                            value={value}
                            invalid={errors?.surname}
                        />
                    )} name="surname" control={control}/>
                    {FormHelper.generateFormFeedback(errors, 'surname', errors?.surname?.message)}
                </div>
                <div className="mb-3">
                    <Label className="form-label">Email</Label>
                    <Controller rules={{
                        required: true
                    }} render={({field: {value, onChange}}) => (
                        <Input
                            name="email"
                            className="form-control"
                            type="email"
                            onChange={onChange}
                            value={value}
                            invalid={errors?.email}
                        />
                    )} name="email" control={control}/>
                    {FormHelper.generateFormFeedback(errors, 'email', errors?.email?.message)}
                </div>
                {!form?.data && (
                    <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Controller rules={{
                            required: true
                        }} render={({field: {value, onChange}}) => (
                            <Input
                                name="password"
                                className="form-control"
                                type="password"
                                onChange={onChange}
                                value={value}
                                invalid={errors?.password}
                            />
                        )} name="password" control={control}/>
                        {FormHelper.generateFormFeedback(errors, 'password', errors?.password?.message)}
                    </div>
                )}
                <div className="mb-3">
                    <Label className="form-label">Role</Label>
                    <Controller rules={{
                        required: true
                    }} render={({field: {value, onChange}}) => (
                        <CustomSelect errors={errors} name="role_id" onChange={onChange} value={value}
                                      options={permissions}/>
                    )} name="role_id" control={control}/>
                    {FormHelper.generateFormFeedback(errors, 'role_id', errors?.role_id?.message)}
                </div>
                <div className="mb-3">
                    <Label className="form-label">Status</Label>
                    <Controller rules={{
                        required: true
                    }} render={({field: {value, onChange}}) => (
                        <CustomSelect errors={errors} name="status1" onChange={onChange} value={value}
                                      options={statuses}/>
                    )} name="status" control={control}/>
                    {FormHelper.generateFormFeedback(errors, 'status', errors?.status?.message)}
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end gap-1">
                    <Button outline type="button" onClick={() => setModal(false)} color="secondary">Close</Button>
                    <Button disabled={loader} type="submit" color="primary">
                        {loader ? <Spinner color="light" size="sm"/> : 'Save'}
                    </Button>
                </div>
            </ModalFooter>
        </form>
    )
}

export default AddModal
