import LocalStorageManager from "./localStorageManager";

class User {
    isAdmin = (roles) => {
        return roles?.admin?.value === LocalStorageManager.get('user').role_id
    }

    getFirstChars = (user) => {
        return `${user?.name?.[0]?.toUpperCase()}${user?.surname?.[0]?.toUpperCase()}`
    }
}

export default new User()
