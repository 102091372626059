import FlatPicker from "react-flatpickr";

const CustomDate = ({value, onChange, ...props}) => {
    return (
        <FlatPicker
            {...props}
            className="form-control d-block"
            value={value}
            onChange={onChange}
            options={{
                locale: 'en'
            }}
        />
    )
}

export default CustomDate
