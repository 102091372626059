import {useEffect, useState} from "react";
import Tag from "../../api/tags";
import {Controller} from "react-hook-form";
import {Label} from "reactstrap";
import CustomSelect from "../../components/CustomSelect";

const Tags = ({control, errors, refreshData, setValue}) => {
    const [tags, setTags] = useState([])

    const fetchTags = async () => {
        const {data} = await Tag.getSelect()
        setTags(data)
    }

    useEffect(() => {
        if (refreshData?.tags?.length) {
            setTags(prev => ([
                ...prev,
                ...refreshData?.tags
            ]))
            setValue('tags', refreshData?.tags)
        }
    }, [refreshData])

    useEffect(() => {
        fetchTags()
    }, [])

    return (
        <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
            <div className="mb-3">
                <Label>Tags</Label>
                <CustomSelect isClearable onChange={onChange} isMulti value={value} errors={errors} options={tags}
                              name="tags"/>
            </div>
        )} name="tags" control={control}/>
    )
}

export default Tags
