import {Button, Card, CardBody, Spinner} from "reactstrap";
import FancyBox from "../../components/Common/FancyBox";
import {useParams} from "react-router-dom";

const Photos = ({watch, setValue, generateImages, loaders}) => {
    const {id} = useParams()
    const keyword = watch(`keyword`)
    const photos = watch('photos')

    const removePhoto = (i) => {
        const newData = [...photos]
        setValue('photos', newData.filter((_, index) => index !== i))
    }

    return (
        <Card>
            <h5 className="card-header bg-transparent border-bottom text-uppercase">
                FEATURED IMAGES
            </h5>
            <CardBody>
                {photos?.length > 0 && (
                    <FancyBox
                        options={{
                            Carousel: {
                                infinite: false,
                            },
                        }}
                    >
                        <div className="d-flex flex-wrap mb-3 gap-2">
                            {photos?.map((item, index) => (
                                <div className="d-flex flex-column justify-content-center align-items-center gap-1">
                                    <a key={index} data-fancybox="gallery"
                                       href={item}>
                                        <img
                                            src={item}
                                            alt={"Image"}
                                            width={50}
                                            height={50}
                                            className="clickable-image"
                                            style={{cursor: "pointer", objectFit: 'cover', marginRight: '4px'}}
                                        />
                                    </a>
                                    <Button color="danger" size="sm"
                                            onClick={() => removePhoto(index)}>
                                        Remove
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </FancyBox>
                )}
                <div className="d-flex justify-content-end">
                    <Button onClick={generateImages} type="button" color="primary"
                            disabled={!keyword || loaders?.generateImages || id}>
                        {loaders?.generateImages ?
                            <Spinner size="sm" color="light"/> : 'Generate images'}
                    </Button>
                </div>
            </CardBody>
        </Card>
    )
}

export default Photos
