const getElement = (value, label) => {
    return {value, label}
}

export const articleStatuses = [
    getElement(1, 'Active'),
    getElement(2, 'Deactive'),
]

export const articleProgressStatuses = [
    getElement(1, 'Completed'),
    getElement(2, 'In progress'),
    getElement(3, 'Fail'),
]
