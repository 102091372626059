import {Controller, useForm} from "react-hook-form";
import {Button, Input, Label, ModalBody, ModalFooter, Spinner, UncontrolledTooltip} from "reactstrap";
import FormHelper from "../../helpers/form";
import {useEffect, useState} from "react";
import Api from 'api/category'
import CustomSelect from "../../components/CustomSelect";
import {categoryTypes, categoryTypesArr} from "../../data/categoryTypes";
import ThirdParty from "../../api/third-party";
import FancyBox from "../../components/Common/FancyBox";
import {toast} from "react-toastify";

const Add = ({fetchData, form, setForm, forArticle}) => {
    const {control, handleSubmit, reset, setError, setValue, watch, formState: {errors}} = useForm({
        defaultValues: {
            type: categoryTypes.main
        }
    })
    const [photos, setPhotos] = useState([])
    const [loader, setLoader] = useState(false)
    const [searchLoader, setSearchLoader] = useState(false)
    const data = form?.data

    const submit = async values => {
        if (!values?.image) {
            return toast.error('Please select image')
        }
        setLoader(true)
        try {
            await Api[values?.id ? 'update' : 'add'](FormHelper.validateApiData(values))
            fetchData(1)
            setForm({})
        } catch (e) {
            FormHelper.setApiErrors(e.response.data, setError)
        } finally {
            setLoader(false)
        }
    }

    const name = watch('name')

    const getPhotos = async (keyword) => {
        setSearchLoader(true)
        const {data} = await ThirdParty.searchPhoto({keyword: keyword || name})
        setPhotos(data?.small_urls)
        setSearchLoader(false)
    }

    useEffect(() => {
        if (data) {
            reset({
                ...data,
                type: categoryTypesArr.find(item => item.value === data.type)
            })
            getPhotos(data?.name)
        }
    }, [form])

    return (
        <form onSubmit={handleSubmit(submit)}>
            <ModalBody>
                <div className="mb-3">
                    <Label for="name">Name</Label>
                    <div className="d-flex gap-2">
                        <Controller rules={{required: true}} name="name" control={control}
                                    render={({field: {value, onChange}}) => (
                                        <Input
                                            name="name"
                                            id="name"
                                            value={value}
                                            onChange={onChange}
                                            className={errors?.name && 'is-invalid'}
                                        />
                                    )}/>
                        <Button id="search-image" color="primary" disabled={!name || searchLoader}
                                onClick={() => getPhotos()}>
                            {searchLoader ? <Spinner size="sm" color="light"/> : <i className="fa fa-search"/>}
                        </Button>
                        <UncontrolledTooltip target="search-image" placement="bottom">
                            Search Image
                        </UncontrolledTooltip>
                    </div>
                    {FormHelper.generateFormFeedback(errors, 'name')}
                </div>
                {photos?.length > 0 && (
                    <FancyBox
                        options={{
                            Carousel: {
                                infinite: false,
                            },
                        }}
                    >
                        <div className="d-flex flex-wrap mb-3 gap-2">
                            {photos?.map((item, index) => (
                                <div className="d-flex flex-column justify-content-center align-items-center gap-1">
                                    <a key={index} data-fancybox="gallery"
                                       href={item}>
                                        <img
                                            src={item}
                                            alt={"Image"}
                                            width={50}
                                            height={50}
                                            className="clickable-image"
                                            style={{cursor: "pointer", objectFit: 'cover', marginRight: '4px'}}
                                        />
                                    </a>
                                    <Button color={item === watch('image') ? 'success' : 'primary'}
                                            onClick={() => setValue('image', item)}
                                            size="sm">
                                        {item === watch('image') ? 'Selected' : 'Select'}
                                    </Button>
                                </div>
                            ))}
                        </div>
                    </FancyBox>
                )}
                <div className="mb-3">
                    <Label for="type">Type</Label>
                    <Controller render={({field: {value, onChange}}) => (
                        <CustomSelect onChange={onChange} value={value} name="type" options={categoryTypesArr}/>
                    )} control={control} name="type"/>
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end gap-1">
                    <Button outline type="button" onClick={() => setForm({})} color="secondary">Close</Button>
                    <Button onClick={() => {
                        forArticle && handleSubmit((data) => submit(data))()
                    }} disabled={loader} type={forArticle ? 'button' : 'submit'} color="primary">
                        {loader ? <Spinner color="light" size="sm"/> : 'Save'}
                    </Button>
                </div>
            </ModalFooter>
        </form>
    )
}

export default Add
