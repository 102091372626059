import {Controller} from "react-hook-form";
import {Button, Label, Spinner} from "reactstrap";
import FormHelper from "../../helpers/form";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Content = ({control, watch, errors, generateFAQ, loaders, setValue}) => {
    const selectedLang = watch('selectedLang')?.value
    const name = `${selectedLang}.faq`
    const content = watch(`${selectedLang}.content`)

    return (
        <div>
            <div className="mb-3">
                <Label for={name}>FAQ</Label>
                <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                    <CKEditor
                        editor={ClassicEditor}
                        data={value}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setValue(name, data)
                        }}
                    />
                )} name={name} control={control}/>
                {FormHelper.generateFormFeedback(errors, name)}
            </div>
            <div className="d-flex justify-content-end">
                <Button type="button" color="primary" disabled={!content || loaders?.generateFAQ}
                        onClick={generateFAQ}>
                    {loaders?.generateFAQ ?
                        <Spinner size="sm" color="light"/> : 'Generate FAQ'}
                </Button>
            </div>
        </div>
    )
}

export default Content
