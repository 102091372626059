import {Card, CardBody, Label} from "reactstrap";
import {Controller} from "react-hook-form";
import CustomSelect from "../../components/CustomSelect";
import ChatGptModels from "../../data/chatGptModels.json";

const Models = ({control, errors}) => {
    return (
        <Card>
            <h5 className="card-header bg-transparent border-bottom text-uppercase">
                Model settings
            </h5>
            <CardBody>
                <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                    <div className="mb-3">
                        <Label>ChatGPT model</Label>
                        <CustomSelect onChange={onChange} value={value} errors={errors}
                                      options={Object.values(ChatGptModels)}
                                      name="chatgpt_model"/>
                    </div>
                )} name="chatgpt_model" control={control}/>
            </CardBody>
        </Card>
    )
}

export default Models
