import {Button, Card, CardBody, CardHeader, CardTitle, Col, Row, Spinner, UncontrolledTooltip} from "reactstrap";

const Filter = ({submit, isFetching, children, reset}) => {
    return <Col sm={12}>
        <Card>
            <CardHeader>
                <CardTitle>Filters</CardTitle>
            </CardHeader>
            <CardBody>
                <form onSubmit={submit}>
                    <Row>
                        {children}
                        <Col sm={12}>
                            <div className="d-flex justify-content-end mt-3">
                                <div className="d-flex gap-1">
                                    <Button disabled={isFetching} color="primary" type="button" outline
                                            onClick={reset} id="reset">
                                        {isFetching ? <Spinner size="sm" color="light"/> :
                                            <i className="bx bx-rotate-right"/>}
                                    </Button>
                                    <UncontrolledTooltip target="reset"
                                                         placement="bottom">
                                        Reset
                                    </UncontrolledTooltip>
                                    <Button color="primary" disabled={isFetching}>
                                        {isFetching ? <Spinner size="sm" color="light"/> : 'Search'}
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </form>
            </CardBody>
        </Card>
    </Col>
}

export default Filter
