import {FormFeedback} from "reactstrap";
import React from 'react'
import {toast} from "react-toastify";
import moment from "moment";

const setApiErrors = (data, setError) => {
    if (!data?.data) {
        toast.error(data?.message)
        return
    }
    Object.keys(data?.data).map(item => {
        setError(item, {
            type: 'custom',
            message: Array.isArray(data?.data[item]) ? data?.data[item]?.[0] : data?.data[item]
        })
    })
}

const generateFormFeedback = (errors, name, message) => {
    const error = errors?.[name]
    return error ? (
        <FormFeedback
            type="invalid">{message || 'Field is required'}</FormFeedback>
    ) : null
}

const convertToSelectValue = data => {
    return data?.map(item => {
        return {
            label: item.name,
            value: item.id
        }
    })
}

const generateFileUrl = (url) => {
    if (window.location.href.includes('newtimes')) {
        return process.env.REACT_APP_PROD_FILE_URL + url
    }
    return process.env.REACT_APP_FILE_URL + url
}

const generateSiteUrl = (url) => {
    if (window.location.href.includes('newtimes')) {
        return process.env.REACT_APP_PROD_SITE_URL + url
    }
    return process.env.REACT_APP_SITE_URL + url
}


const validateApiData = (data) => {
    const localData = Object.keys(data)
    const body = {}
    localData.forEach(item => {
        const value = data[item]
        if (value && value != undefined && value != null && value != '') {
            body[item] = value
        }
    })

    localData.forEach(item => {
        const value = body[item]
        if (value?.label) {
            body[item] = value?.value
        }
    })

    return body
}

const getDate = (date, format = 'DD.MM.YYYY HH:mm') => {
    return moment(date).format(format)
}

const convertFormDate = date => {
    if (!date) {
        return null
    }

    if (Array.isArray(date)) {
        return moment(date[0]).format('YYYY-MM-DD')
    }

    return moment(date).format('YYYY-MM-DD')
}

export default {
    generateFormFeedback,
    setApiErrors,
    convertToSelectValue,
    generateFileUrl,
    validateApiData,
    getDate,
    convertFormDate,
    generateSiteUrl
}
