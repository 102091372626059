import {Button, Card, CardBody, Col, Row, Spinner} from "reactstrap";
import {useForm} from "react-hook-form";
import ChatGptPrompts from "../../data/chatGptPrompts.json";
import ChatGptStyles from "../../data/chatGptStyles.json";
import ChatGptTones from "../../data/chatGptTones.json";
import ChatGptModels from "../../data/chatGptModels.json";
import Styles from "./Styles";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Topic from "./Topic";
import {useEffect, useState} from "react";
import ThirdParty from "../../api/third-party";
import {toast} from "react-toastify";
import Language from "./Language";
import Title from "./Title";
import Categories from "./Categories";
import ChatGPTCategories from "./ChatGPTCategories";
import Sections from "./Sections";
import Content from "./Content";
import Tags from "./Tags";
import Photos from "./Photos";
import Models from "./Models";
import Prompts from "./Prompts";
import FAQ from "./FAQ";
import Excerpt from "./Excerpt";
import {useNavigate, useParams} from "react-router-dom";
import Api from "../../api/articles";
import FormHelper from "../../helpers/form";
import Helpler from "../../helpers/Helpler";
import {v4 as uuidv4} from 'uuid';

const ArticleForm = () => {
    const {id} = useParams()
    const {control, watch, getValues, handleSubmit, reset, setValue, setError, formState: {errors}} = useForm({
        defaultValues: {
            ...ChatGptPrompts,
            writing_style: ChatGptStyles.creative,
            writing_tone: ChatGptTones.neutral,
            chatgpt_model: ChatGptModels["gpt-3.5-turbo"],
            section_count: {value: 5, label: 5},
            paragraph_count: {value: 3, label: 3},
            selectedLang: {value: 'en', label: 'English'}
        }
    })

    const [supportedLanguages, setSupportedLanguages] = useState([{value: 'en', label: 'English'}])
    const [refreshData, setRefreshData] = useState({
        tags: [],
        categories: []
    })
    const [loaders, setLoaders] = useState({})
    const [isFetching, setIsFetching] = useState(false)

    const selectedLang = watch('selectedLang')?.label
    const selectedLangValue = watch('selectedLang')?.value

    const navigate = useNavigate()

    const generateCategories = async (title) => {
        const values = getValues()
        const {data} = await ThirdParty.searchContent({
            model: values?.chatgpt_model.value,
            content: ChatGptPrompts.categories_prompt
                .replace('$count', Helpler.generateRandomNumber(1, 5))
                .replace('$title', title)
        })
        setRefreshData(prev => ({
            ...prev,
            categories: Helpler.getOnlyWords(data?.content)?.filter(item => item?.length > 0)?.map(item => {
                return {
                    value: uuidv4(),
                    label: item
                }
            })
        }))
    }

    const generateTags = async (title) => {
        const values = getValues()
        const {data} = await ThirdParty.searchContent({
            model: values?.chatgpt_model.value,
            content: ChatGptPrompts.tags_prompt
                .replace('$count', Helpler.generateRandomNumber(1, 20))
                .replace('$title', title)
        })
        setRefreshData(prev => ({
            ...prev,
            tags: Helpler.getOnlyWords(data?.content)?.filter(item => item?.length > 0)?.map(item => {
                return {
                    value: uuidv4(),
                    label: item
                }
            })
        }))
    }

    const generateTitle = async () => {
        setLoaders(prev => ({
            ...prev,
            generateTitle: true
        }))
        const values = getValues()
        try {
            const {data} = await ThirdParty.searchContent({
                model: values?.chatgpt_model.value,
                content: ChatGptPrompts.title_prompt
                    .replace('$keyword', values?.keyword)
                    .replace('$language', selectedLang)
                    .replace('$writingStyle', values?.writing_style?.value)
                    .replace('$writingTone', values?.writing_tone?.value),
            })
            setValue(`${selectedLangValue}.title`, data?.content)
            generateCategories(data?.content)
            generateTags(data?.content)
        } catch (e) {
            toast.error('Error')
        } finally {
            setLoaders(prev => ({
                ...prev,
                generateTitle: false
            }))
        }
    }

    const generateSections = async () => {
        setLoaders(prev => ({
            ...prev,
            generateSections: true
        }))
        const values = getValues()
        try {
            const {data} = await ThirdParty.searchContent({
                model: values?.chatgpt_model.value,
                content: ChatGptPrompts.section_prompt
                    .replace('$title', values?.[selectedLangValue]?.title)
                    .replace('$sectionsCount', values?.section_count?.value)
                    .replace('$language', selectedLang)
                    .replace('$writingStyle', values?.writing_style?.value)
                    .replace('$writingTone', values?.writing_tone?.value),
            })
            setValue(`${selectedLangValue}.section`, data?.content)
        } catch (e) {
            toast.error('Error')
        } finally {
            setLoaders(prev => ({
                ...prev,
                generateSections: false
            }))
        }
    }

    const generateContent = async () => {
        setLoaders(prev => ({
            ...prev,
            generateContent: true
        }))
        const values = getValues()
        try {
            const {data} = await ThirdParty.searchContent({
                model: values?.chatgpt_model.value,
                content: ChatGptPrompts.content_prompt
                    .replace('$title', values?.[selectedLangValue]?.title)
                    .replace('$sections', values?.[selectedLangValue]?.section)
                    .replace('$paragraphsPerSection', values?.paragraph_count?.value)
                    .replace('$language', selectedLang)
                    .replace('$writingStyle', values?.writing_style?.value)
                    .replace('$writingTone', values?.writing_tone?.value),
            })
            setValue(`${selectedLangValue}.content`, data?.content)
        } catch (e) {
            toast.error('Error')
        } finally {
            setLoaders(prev => ({
                ...prev,
                generateContent: false
            }))
        }
    }

    const generateImages = async () => {
        setLoaders(prev => ({
            ...prev,
            generateImages: true
        }))
        try {
            const {data} = await ThirdParty.searchPhoto({keyword: getValues()?.keyword})
            setValue('photos', data?.small_urls)
        } catch (e) {
            toast.error('Error')
        } finally {
            setLoaders(prev => ({
                ...prev,
                generateImages: false
            }))
        }
    }

    const generateFAQ = async () => {
        setLoaders(prev => ({
            ...prev,
            generateFAQ: true
        }))
        const values = getValues()
        try {
            const {data} = await ThirdParty.searchContent({
                model: values?.chatgpt_model.value,
                content: ChatGptPrompts.faq_prompt
                    .replace('$content', values?.[selectedLangValue]?.content)
                    .replace('$language', selectedLang)
                    .replace('$writingStyle', values?.writing_style?.value)
                    .replace('$writingTone', values?.writing_tone?.value),
            })
            setValue(`${selectedLangValue}.faq`, data?.content)
        } catch (e) {
            toast.error('Error')
        } finally {
            setLoaders(prev => ({
                ...prev,
                generateFAQ: false
            }))
        }
    }

    const generateExcerpt = async () => {
        setLoaders(prev => ({
            ...prev,
            generateExcerpt: true
        }))
        const values = getValues()
        try {
            const {data} = await ThirdParty.searchContent({
                model: values?.chatgpt_model.value,
                content: ChatGptPrompts.excerpt_prompt
                    .replace('$title', values?.[selectedLangValue]?.title)
                    .replace('$language', selectedLang)
                    .replace('$writingStyle', values?.writing_style?.value)
                    .replace('$writingTone', values?.writing_tone?.value),
            })
            setValue(`${selectedLangValue}.excerpt`, data?.content)
        } catch (e) {
            toast.error('Error')
        } finally {
            setLoaders(prev => ({
                ...prev,
                generateExcerpt: false
            }))
        }
    }


    const generateAll = async () => {
        setLoaders(prev => ({
            ...prev,
            generateAll: true
        }))
        generateImages()
        await generateTitle()
        generateExcerpt()
        await generateSections()
        await generateContent()
        await generateFAQ()
        setLoaders(prev => ({
            ...prev,
            generateAll: false
        }))
    }

    const save = async (values) => {
        setLoaders(prev => ({
            ...prev,
            save: true
        }))

        try {
            let formData = {
                ...values,
                ...values?.en,
                main_categories: values?.main_categories?.map(item => item?.label),
                gpt_categories: values?.gpt_categories?.map(item => item?.label),
                tags: values?.tags?.map(item => item?.label),
                section_count: values?.section_count?.value,
                paragraph_count: values?.paragraph_count?.value,
                writing_style: values?.writing_style?.value,
                writing_tone: values?.writing_tone?.value,
                chatgpt_model: values?.chatgpt_model?.value,
            }
            if (!id) {
                await Api.add(formData)
            } else {
                const bodies = {}
                values?.languages?.langs.forEach(item => {
                    bodies[item] = values?.[item]
                    delete formData[item]
                })
                formData = {
                    ...formData,
                    main_categories: values?.main_categories?.map(item => item?.value),
                    gpt_categories: values?.gpt_categories?.map(item => item?.value),
                    tags: values?.tags?.map(item => item?.value),
                    bodies
                }
                await Api.update(formData)
            }
            navigate('/articles')
        } catch (e) {
            FormHelper.setApiErrors(e.response.data, setError)
        } finally {
            setLoaders(prev => ({
                ...prev,
                save: false
            }))
        }

    }

    const fetchArticle = async () => {
        setIsFetching(true)
        const {data} = await Api.getById(id)
        const article = data?.article
        const langs = article?.languages?.select
        setSupportedLanguages(langs)

        const articleData = {}
        langs.forEach((item) => {
            articleData[item.value] = {}
        })

        langs.forEach((item, index) => {
            articleData[item.value].title = article?.title?.[index]?.name
            articleData[item.value].section = article?.section?.[index]?.name
            articleData[item.value].content = article?.content?.[index]?.name
            articleData[item.value].faq = article?.faq?.[index]?.name
            articleData[item.value].excerpt = article?.excerpt?.[index]?.name
        })

        reset({
            ...article,
            photos: article?.photos?.map(item => item?.photo),
            main_categories: article?.main_categories?.map(item => {
                return {
                    value: item?.category_id,
                    label: item?.name
                }
            }),
            gpt_categories: article?.gpt_categories?.map(item => {
                return {
                    value: item?.category_id,
                    label: item?.name
                }
            }),
            tags: article?.tags?.map(item => {
                return {
                    value: item?.tag_id,
                    label: item?.name
                }
            }),
            chatgpt_model: {
                value: article?.chatgpt_model,
                label: article?.chatgpt_model
            },
            section_count: {
                value: article?.section_count,
                label: article?.section_count
            },
            paragraph_count: {
                value: article?.paragraph_count,
                label: article?.paragraph_count
            },
            writing_style: {
                value: article?.writing_style,
                label: article?.writing_style
            },
            writing_tone: {
                value: article?.writing_tone,
                label: article?.writing_tone
            },
            selectedLang: {value: 'en', label: 'English'},
            ...articleData
        })
        setIsFetching(false)
    }


    useEffect(() => {
        if (id) {
            fetchArticle()
        }
    }, [id])

    return (
        <div className="page-content">
            <div className="container-fluid">
                <Breadcrumbs breadcrumbItem={`Add article`}/>
                {isFetching ? <Card>
                    <CardBody>
                        <div className="d-flex justify-content-center p-5">
                            <Spinner color="primary" size="lg"/>
                        </div>
                    </CardBody>
                </Card> : (
                    <form onSubmit={handleSubmit(save)}>
                        {supportedLanguages?.map(item => (
                            getValues()?.selectedLang?.value === item?.value && <Row key={item.value}>
                                <Col sm={12} md={8}>
                                    <Language control={control} supportedLanguages={supportedLanguages}/>
                                    <Card>
                                        <CardBody>
                                            <Title control={control} watch={watch} errors={errors}/>
                                            <Sections control={control} watch={watch} errors={errors} loaders={loaders}
                                                      generateSections={generateSections}/>
                                            <Content control={control} watch={watch} errors={errors} loaders={loaders}
                                                     generateContent={generateContent} setValue={setValue}/>
                                            <FAQ control={control} errors={errors} loaders={loaders} setValue={setValue}
                                                 watch={watch} generateFAQ={generateFAQ}/>
                                            <Excerpt generateExcerpt={generateExcerpt} watch={watch} errors={errors}
                                                     loaders={loaders} control={control}/>
                                            <Button disabled={loaders?.save} type="submit" className="w-100 mt-3"
                                                    color="primary">
                                                {loaders?.save ? <Spinner size="sm" color="light"/> : 'Save'}
                                            </Button>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col sm={12} md={4}>
                                    <Styles control={control}/>
                                    <Topic control={control} loaders={loaders} errors={errors} watch={watch}
                                           generateAll={generateAll}
                                           generateTitle={generateTitle}/>
                                    <Card>
                                        <h5 className="card-header bg-transparent border-bottom text-uppercase">
                                            PARAMETERS
                                        </h5>
                                        <CardBody>
                                            <Categories control={control} errors={errors} setValue={setValue}/>
                                            <ChatGPTCategories control={control} errors={errors}
                                                               refreshData={refreshData}
                                                               setValue={setValue}/>
                                            <Tags control={control} errors={errors} refreshData={refreshData}
                                                  setValue={setValue}/>
                                        </CardBody>
                                    </Card>
                                    <Photos loaders={loaders} setValue={setValue} watch={watch}
                                            generateImages={generateImages}/>
                                    <Models control={control} errors={errors}/>
                                    <Prompts control={control}/>
                                </Col>
                            </Row>
                        ))}
                    </form>
                )}
            </div>
        </div>
    )
}

export default ArticleForm
