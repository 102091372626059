// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-md {
  text-align: center;
  font-size: 1.5rem;
  line-height: 4rem; }

.css-1nmdiq5-menu > div {
  background-color: #fff !important; }

.pagination .page-item .page-link {
  border-radius: 0; }

.pagination .page-item:first-child .page-link {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px; }

.pagination .page-item:last-child .page-link {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px; }
`, "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB,EAAA;;AAGnB;EACE,iCAAiC,EAAA;;AAGnC;EAGM,gBAAgB,EAAA;;AAHtB;EAQQ,2BAA2B;EAC3B,8BAA8B,EAAA;;AATtC;EAeQ,4BAA4B;EAC5B,+BAA+B,EAAA","sourcesContent":[".avatar-md {\n  text-align: center;\n  font-size: 1.5rem;\n  line-height: 4rem;\n}\n\n.css-1nmdiq5-menu > div {\n  background-color: #fff !important;\n}\n\n.pagination {\n  .page-item {\n    .page-link {\n      border-radius: 0;\n    }\n\n    &:first-child {\n      .page-link {\n        border-top-left-radius: 6px;\n        border-bottom-left-radius: 6px;\n      }\n    }\n\n    &:last-child {\n      .page-link {\n        border-top-right-radius: 6px;\n        border-bottom-right-radius: 6px;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
