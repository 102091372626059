import {Button, ModalBody, ModalFooter} from "reactstrap";

const TranslatedData = ({setForm, form}) => {
    const data = form?.data?.bodies

    return (
        <>
            <ModalBody>
                <div className="d-flex gap-2 justify-content-between flex-wrap">
                    {data?.map((item) => (
                        <div className="d-flex gap-2 font-size-18" key={item.id}>
                            <span>{item.language}:</span>
                            <b>{item.name}</b>
                        </div>
                    ))}
                </div>
            </ModalBody>
            <ModalFooter>
                <div className="d-flex justify-content-end gap-1">
                    <Button outline type="button" onClick={() => setForm({})} color="secondary">Close</Button>
                </div>
            </ModalFooter>
        </>
    )
}

export default TranslatedData