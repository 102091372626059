import {Controller} from "react-hook-form";
import {Button, Label, Spinner} from "reactstrap";
import FormHelper from "../../helpers/form";
import CustomSelect from "../../components/CustomSelect";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Content = ({control, watch, errors, generateContent, loaders, setValue}) => {
    const selectedLang = watch('selectedLang')?.value
    const name = `${selectedLang}.content`
    const section = watch(`${selectedLang}.section`)

    return (
        <div>
            <div className="mb-3">
                <Label for="paragraph_count">Paragraph count</Label>
                <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                    <CustomSelect options={Array.from({length: 50}).map((item, index) => {
                        return {value: index + 1, label: index + 1}
                    })} errors={errors} value={value} onChange={onChange} name="paragraph_count"/>
                )} name="paragraph_count" control={control}/>
                {FormHelper.generateFormFeedback(errors, 'paragraph_count')}
            </div>
            <div className="mb-3">
                <Label for={name}>Content</Label>
                <Controller rules={{required: true}} render={({field: {value, onChange}}) => (
                    <CKEditor
                        editor={ClassicEditor}
                        data={value}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setValue(name, data)
                        }}
                    />
                )} name={name} control={control}/>
                {FormHelper.generateFormFeedback(errors, name)}
            </div>
            <div className="d-flex justify-content-end">
                <Button type="button" color="primary" disabled={!section || loaders?.generateContent}
                        onClick={generateContent}>
                    {loaders?.generateContent ?
                        <Spinner size="sm" color="light"/> : 'Generate content'}
                </Button>
            </div>
        </div>
    )
}

export default Content
